<template>
  <section class="about">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      max-height="420"
      >
        <v-layout
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-card class="text-center" style="background:none !important; box-shadow: none; clear: both;">
            <v-card-text>
              <div style="color:#333; font-size: 48px;"></div> <br/>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-img>
    <v-container  grid-list-md text-center class="ctk-div">
      <v-layout wrap>
        <v-flex class="text-left">
          <span v-html="about.introduction">{{about.introduction}}</span>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 1,
    tabSubMenu: null,
    introduce: null,
    introduceText: null,
    subMenu: null,
    innerWidth: window.innerWidth,
    about: {
      'introduction': null
    }
  }),
  components: {
    /*
    Carousel3d,
    Slide
    */
  },
  created () {
    document.querySelector('#menu_about').classList.add('v-btn--active')
    this.getPageData()
    this.getAbout()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        let locale = localStorage.getItem('locale')
        if (!locale) {
          locale = 'cn'
        }
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    },
    tabSubMenu: {
      handler: function (val, oldVal) {
        if (val === 'tab-submenu-3') {
          let lks = this.$store.getters.companyInfo.link
          let links = []
          if (lks) {
            let i = null
            for (i in lks) {
              if (lks[i].logo) {
                links.push(lks[i])
              }
            }
          }
          this.info.links = links
        }
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      let links = []
      if (this.$store.getters.companyInfo.links) {
        links = this.$store.getters.companyInfo.links
      }
      return {
        links: links,
        banners: banners
      }
    }
  },
  methods: {
    getPageData () {
      this.introduce = this.$t('about.introduce')
      this.subMenu = this.$t('about.menu')
    },
    getAbout () {
      this.https.get('about').then(response => {
        if (response.code === 0) {
          this.about = response.data
        }
      })
    }
  }
}
</script>
